import Vue from 'vue'
//york,230222 vue-i18n
import VueI18n from 'vue-i18n'
import zh from './langs/zh'
import en from './langs/en'
//按需加载element，使用element/ui/lib/locale。
// 如果兼容导入，需要使用i18n来初始化element-ui
import ElementLocale from 'element-ui/lib/locale'

//通过插件的形式挂载，york/230222
Vue.use(VueI18n)

const i18n = new VueI18n({
    // 指定语言类型
    // locale: 'en-US', //语言标识
    // locale: 'zh-CN', //语言标识
    // locale: localStorage.getItem('languageSet') || 'en-US',
    //y230711
    // locale: localStorage.getItem('languageSet') || process.env.VUE_APP_DEFAULT_LANGUAGE,
    locale: localStorage.getItem('languageSet') || process.env.VUE_APP_DEFAULT_LANGUAGE,
    // locale: localStorage.getItem('languageSet') || 'zh-CN',
    // locale: localStorage.lang || 'en-US',
    // this.$i18n.locale //通过切换locale的值来实现语言切换
    //将语言包加入到插件语言教程里 
    messages: {
        // 'zh-CN': require('./langs/zh'), //中文语言包
        // 'en-US': require('./langs/en') //英文语言包
        'zh-CN': zh, //中文语言包
        'en-US': en //英文语言包
    }
})

//重点，为了实现element插件的多语言切换
ElementLocale.i18n((key,value)=>i18n.t(key,value))



export default i18n